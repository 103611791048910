<template>
  <div>
    <v-dialog v-model="dialogTag" max-width="1100">
      <v-card>
        <v-card-text>
          <h1 class="headline">
            Imprimir Etiquetas
          </h1>
          <div class="grey--text">Selecione o modelo da etiqueta que você deseja imprimir.
          </div>
        </v-card-text>

        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm6 md6 lg6>
              <v-select :error-messages="errors.collect('type')"
                        data-vv-as="type"
                        data-vv-name="type"
                        v-validate="{ required: true }"

                        :items="type.items"
                        label="Tipo da Etiqueta "
                        light
                        v-model="print.type"
                        :disabled="allDisabled"

                        @change="changeType"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6 md6 lg6>
              <v-alert
                  :value="true"
                  type="info"
              >
                Durante o processo de impressão, selecione a opção <strong>4x4</strong> e <strong>ajustar a área de
                impressão</strong> para garantir que as
                etiquetas sejam impressas no formato correto.
              </v-alert>

              <v-alert
                  :value="true"
                  type="warning"
              >
                Escolha o <strong>número de cópias
              </strong> durante o processo de impressão.
              </v-alert>

              <v-spacer class="mb-2"></v-spacer>
            </v-flex>

            <v-flex xs12 v-if="tag.loading">
              <v-progress-linear color="primary" height="4" :indeterminate="true"></v-progress-linear>
            </v-flex>

            <v-flex xs6 sm4 md3 lg2 v-for="(item, index) in tag.items" :key="index">
              <div v-if="item && item.id">
                <v-hover v-if="item.type == 0">
                  <v-card style="width: 150px;" class="pa-2 cursor-pointer no-select" @click="autoPrint(item)"
                          :class="`elevation-${item.count ? 10 : 0} ${item.count ? 'blue' : 'grey'} lighten-4`"
                          slot-scope="{}">

                    <div class="text-xs-center">
                      <span class="white"
                            style="height: 12px;  width: 12px;  border-radius: 50%;  display: inline-block;">
                      </span>
                    </div>

                    <!--                    <v-btn small v-if="hover"-->
                    <!--                           absolute-->
                    <!--                           dark-->
                    <!--                           fab-->
                    <!--                           bottom-->
                    <!--                           left-->
                    <!--                           color="red"-->
                    <!--                           @click="addCountPrint(item, '-')"-->
                    <!--                    >-->
                    <!--                      <v-icon>remove</v-icon>-->
                    <!--                    </v-btn>-->

                    <!--                    <v-btn small v-if="hover"-->
                    <!--                           absolute-->
                    <!--                           dark-->
                    <!--                           fab-->
                    <!--                           bottom-->
                    <!--                           left-->
                    <!--                           color="green"-->
                    <!--                           style="margin-left: 40px"-->
                    <!--                           @click="addCountPrint(item, '+')"-->
                    <!--                    >-->
                    <!--                      <v-icon>add</v-icon>-->
                    <!--                    </v-btn>-->

                    <div style="text-align: center;">
                      <div>
                        <div style="opacity: 0.7; font-size: 19px; font-weight: bold;">Burguesinha</div>
                        <div style="opacity: 0.7; font-size: 13px; font-weight: bold;">Brecho Chic</div>
                        <div style="opacity: 0.7; font-size: 9px; font-weight: bold;">{{ company.referencia }}
                        </div>
                      </div>

                      <v-card>
                        <v-btn small v-if="item.count"
                               absolute
                               dark
                               fab
                               top
                               right
                               color="blue"
                        >
                          <strong>{{ item.count }}</strong>
                        </v-btn>
                      </v-card>

                      <div
                          v-if="!!parseFloat(item.price) || (!parseFloat(item.price) && !item.description)">
                        <img style="height: 78px; opacity: 0.7"
                             src="https://compras-burguesinha.s3.us-east-2.amazonaws.com/img/qrcode.svg"/>
                      </div>

                      <div v-if="!!item.description">
                        <div style="margin-bottom: 15px;"></div>
                        <div style="min-height: 50px;">
                          <div style="font-size: 16px; font-weight: bold;">{{
                              item.description.toUpperCase()
                            }}
                          </div>
                        </div>
                        <div style="margin-bottom: 15px;"></div>
                      </div>

                      <div style="opacity: 0.7; font-size: 9px; font-weight: bold;">NÃO TROCAMOS PEÇAS</div>
                      <div style="opacity: 0.7; font-size: 6px; font-weight: bold;">{{ codePrice(item.price) }}</div>

                      <div style="margin-bottom: 15px;"></div>

                      <div
                          v-if="!!parseFloat(item.price) || (!parseFloat(item.price) && !item.description)">
                        <div style="font-size: 22px; font-weight: bold;">
                          R$ {{ $options.filters.filterValue(item.price) }}
                        </div>
                      </div>

                      <div v-if="!!item.description">
                        <div style="margin-bottom: 48px;"></div>
                      </div>

                      <div style="opacity: 0.7; font-size: 8px; font-weight: bold;">VERIFIQUE SEU PRODUTO NO ATO DA
                        COMPRA
                      </div>

                      <div style="margin-bottom: 2px;"></div>

                      <div style="opacity: 0.7; font-size: 7px; font-weight: bold;">www.burguesinhabrechochic.com.br
                      </div>
                    </div>
                  </v-card>
                </v-hover>

                <v-hover v-if="item.type == 1">
                  <v-card style="width: 150px;" class="pa-2 cursor-pointer no-select" @click="autoPrint(item)"
                          :class="`elevation-${item.count ? 10 : 0} ${item.count ? 'blue' : 'grey'} lighten-4`"
                          slot-scope="{}">

                    <!--                    <v-btn small v-if="hover"-->
                    <!--                           absolute-->
                    <!--                           dark-->
                    <!--                           fab-->
                    <!--                           bottom-->
                    <!--                           left-->
                    <!--                           color="red"-->
                    <!--                           @click="addCountPrint(item, '-')"-->
                    <!--                    >-->
                    <!--                      <v-icon>remove</v-icon>-->
                    <!--                    </v-btn>-->

                    <!--                    <v-btn small v-if="hover"-->
                    <!--                           absolute-->
                    <!--                           dark-->
                    <!--                           fab-->
                    <!--                           bottom-->
                    <!--                           left-->
                    <!--                           color="green"-->
                    <!--                           style="margin-left: 40px"-->
                    <!--                           @click="addCountPrint(item, '+')"-->
                    <!--                    >-->
                    <!--                      <v-icon>add</v-icon>-->
                    <!--                    </v-btn>-->

                    <div style="text-align: center;">
                      <div>
                        <div style="opacity: 0.7; font-size: 10px; font-weight: bold;">Burguesinha Brechó Chic</div>
                        <div style="opacity: 0.7; font-size: 8px; font-weight: bold;">
                          {{ company.referencia }}
                        </div>
                      </div>

                      <div style="font-size: 20px; font-weight: bold;">
                        R$ {{ $options.filters.filterValue(item.price) }}
                      </div>
                      <div style="opacity: 0.7; font-size: 6px; font-weight: bold;">* DISPOSITIVO ANTI-FURTO *</div>

                      <v-card>
                        <v-btn small v-if="item.count"
                               absolute
                               dark
                               fab
                               top
                               right
                               color="blue"
                        >
                          <strong>{{ item.count }}</strong>
                        </v-btn>
                      </v-card>

                      <img style="opacity: 0.7; height: 72px;"
                           src="https://compras-burguesinha.s3.us-east-2.amazonaws.com/img/qrcode.svg"/>
                      <div style="opacity: 0.7; font-size: 9px; font-weight: bold;">NÃO TROCAMOS PEÇAS</div>
                      <div style="opacity: 0.7; font-size: 6px; font-weight: bold;">{{ codePrice(item.price) }}</div>
                    </div>
                  </v-card>
                </v-hover>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey darken-4" flat @click="dialogTag = false">Fechar</v-btn>

          <v-btn class="green white--text no-margin-right" :loading="loading" @click="printTag">
            Imprimir &nbsp;
            <v-icon>print</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="10000"
                bottom
                color="grey darken-2"
                right auto-height
                v-model="snackBar.status">
      <span v-html="snackBar.text"></span>
      <v-btn @click.native="snackBar.status = false" color="primary" flat>Fechar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import store from "@/store/store";

export default {
  components: {},
  data() {
    return {
      allDisabled: false,
      preview: false,

      snackBar: {
        status: false,
        text: ''
      },

      loading: false,
      loadingDownload: false,

      money: {
        locale: "pt-BR",
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        length: 11,
      },

      dialogTag: false,

      formTab: 0,

      company: {},

      print: {
        type: 0,
        tag: null,
      },

      tag: {items: [], search: '', loading: false},

      type: {
        items: [
          {value: 0, text: 'Etiqueta Tag 2 Colunas'},
          {value: 1, text: 'Etiqueta Adesiva anti-furto'},
        ]
      },
    }
  },
  mounted() {
  },
  created() {
  },
  watch: {
    dialogTag(value) {
      if (value) {
        if (!this.tag.items.length) {
          this.getTags();
        }
      }
    }
  },

  computed: {
    user() {
      let user = store.state.auth.user;

      return user ? user : {};
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
  },
  filters: {},
  methods: {
    codePrice(price) {
      return parseInt(price).toString().padStart(9, '0');
    },

    changeType() {
      this.print.tag = {};
      this.tag.items = [];

      this.getTags()

      this.$forceUpdate();
    },

    autoPrint(item) {
      if (item.count) return;

      this.addCountPrint(item, '+');
      this.printTag();
    },

    async printTag() {
      let items = this.tag.items.filter((item) => item.count > 0);
      if (!items.length) return;

      let fields = {
        type: this.print.type,
      };

      return await this.$validator.validateAll(fields).then(async (response) => {
        if (response) {
          this.loading = true;

          let data = {
            type: this.print.type,
            items
          }

          const response = await this.$http.post('api/tags/downloadTag', data, {responseType: 'blob'});
          const blob = new Blob([response.body], {type: response.headers.get('content-type')});
          const url = window.URL.createObjectURL(blob);

          for (let item of items) item.count = 0;
          // this.tag.items = [];
          // this.dialogTag = false;

          if (this.isSafari) {
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Etiqueta.pdf';
            link.click();
          } else {
            const newTab = window.open(url, '_blank');
            newTab.focus();

            newTab.onload = function () {
              newTab.print();
            };
          }

          this.loading = false;
          return response;
        } else {
          return response;
        }
      });
    },

    getTags() {
      this.tag.loading = true;

      let search = {
        field: 'id',
        type: this.print.type
      };

      store.dispatch('getTagField', search).then((response) => {
        if (response.data) {
          for (let item of response.data) {
            let is = this.tag.items.find(value => {
              return value.id == item.id;
            });

            if (is) {
              const index = this.tag.items.indexOf(is);
              this.$set(this.tag.items, index, item);
            } else {
              this.tag.items.push(item);
            }
          }
        }

        this.tag.loading = false;
      }).catch(() => {
        this.tag.loading = false;
      });
    },

    addCountPrint(item, type) {
      let items = this.tag.items.filter((item) => item.count > 0);
      let sum = items.reduce((total, item) => total + item.count, 0);

      if (sum >= 100 && type === '+') {
        this.snackBar.status = true;
        this.snackBar.text = 'Limite excedido. O máximo permitido por impressora é de 100.';

        return;
      }

      let base = 1;
      if (item.type == 0) base = 2;

      let count = 0;
      if (item.count) count = item.count;

      if (type === '-') {
        if (count > 0) {
          count -= base;
        }
      }

      if (type === '+') {
        count += base;
      }

      item.count = count;

      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>